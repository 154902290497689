import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';


const json = {

    1: { // Exercise num
        unit: 'Starter',
        id: 'SB1-S-P4-E1',
        audio: 'img/FriendsPlus/Page4/Audio/audio.e1.mp3',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page4/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page4/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/tieude.e1.mp3' },
                { url: 'img/FriendsPlus/Page4/E1/3.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page4/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/Rosy.mp3' },
                { url: 'img/FriendsPlus/Page4/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/Tim.mp3' },
                { url: 'img/FriendsPlus/Page4/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/Billy.mp3' },
                { url: 'img/FriendsPlus/Page4/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/Hello.mp3' },
                { url: 'img/FriendsPlus/Page4/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/Goodbye.mp3' },
            ],

        ],
    },
    2: { // Exercise num
        unit: 'Starter',
        id: 'SB1-S-P4-E2',
        audio: 'img/FriendsPlus/Page4/Audio/audio.e2.mp3',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page4/E2/1.jpg' },
                { url: 'img/FriendsPlus/Page4/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/tieude.e1.mp3' },
                { url: 'img/FriendsPlus/Page4/E2/3.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page4/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/Rosy.mp3' },
                { url: 'img/FriendsPlus/Page4/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/Tim.mp3' },
                { url: 'img/FriendsPlus/Page4/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/Billy.mp3' },
                { url: 'img/FriendsPlus/Page4/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/Hello.mp3' },
                { url: 'img/FriendsPlus/Page4/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/Goodbye.mp3' },
            ],

        ],
    },
    3: { // Exercise num
        unit: 'Starter',
        id: 'SB1-S-P4-E3',
        audio: '',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page4/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page4/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page4/E3/3.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/Tim.mp3' },
                { url: 'img/FriendsPlus/Page4/E3/4.jpg' },
                { url: 'img/FriendsPlus/Page4/E3/5.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/Rosy.mp3' },
                { url: 'img/FriendsPlus/Page4/E3/6.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page4/E3/7.jpg' },
                { url: 'img/FriendsPlus/Page4/E3/8.jpg', audioUrl: 'img/FriendsPlus/Page4/Audio/Billy.mp3' },
                { url: 'img/FriendsPlus/Page4/E3/9.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page4/E3/10.jpg' },
            ],
        ],
    },

}

export default json;