import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';


const json = {

  1: { // Exercise num
    unit: 'Culture 1',
    id: 'SB1-C-P52-E1',
    audio: 'img/FriendsPlus/Page52/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page52/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page52/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page52/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/cake.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/candle.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/gift.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/party.mp3' },
        { url: 'img/FriendsPlus/Page52/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page52/Audio/happy birthday.mp3' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Culture 1',
    id: 'SB1-C-P52-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page52/E2/1.jpg' },
      ],
    ]
  },
  3: { // Exercise num
    unit: 'Culture 1',
    id: 'SB1-C-P52-E3',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page52/E3/1.jpg' },
      ],
    ]
  },


}
export default json;
