import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';

const json = {
  1: { // Exercise num
    unit: 'Fluency Time! 1',
    id: 'SB1-FT1-P29-E1',
    audio: 'img/FriendsPlus/Page29/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page29/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page29/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page29/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page29/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page29/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page29/Audio/eyes.mp3' },
        { url: 'img/FriendsPlus/Page29/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page29/Audio/ears.mp3' },
        { url: 'img/FriendsPlus/Page29/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page29/Audio/mouth.mp3' },
        { url: 'img/FriendsPlus/Page29/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page29/Audio/look.mp3' },
        { url: 'img/FriendsPlus/Page29/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page29/Audio/listen.mp3' },
        { url: 'img/FriendsPlus/Page29/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page29/Audio/say.mp3' },
      ],


    ],
  },
  2: { // Exercise num
    unit: 'Fluency Time! 1',
    id: 'SB1-FT1-P29-E2',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page29/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page29/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page29/Audio/eyes.mp3' },
        { url: 'img/FriendsPlus/Page29/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page29/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page29/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page29/Audio/look.mp3' },
        { url: 'img/FriendsPlus/Page29/E2/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page29/E2/7.jpg' },
        { url: 'img/FriendsPlus/Page29/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page29/Audio/listen.mp3' },
        { url: 'img/FriendsPlus/Page29/E2/9.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page29/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page29/E2/11.jpg' },
        { url: 'img/FriendsPlus/Page29/E2/12.jpg', audioUrl: 'img/FriendsPlus/Page29/Audio/say.mp3' },
        { url: 'img/FriendsPlus/Page29/E2/13.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Fluency Time! 1',
    id: 'SB1-FT1-P29-E3',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page29/E3/1.jpg' },
      ],
    ]
  },
  4: { // Exercise num
    unit: 'Fluency Time! 1',
    id: 'SB1-FT1-P29-E4',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page29/E4/1.jpg' },
      ],
    ]
  },
  5: { // Exercise num
    unit: 'Fluency Time! 1',
    id: 'SB1-FT1-P29-E5',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 1,
    exerciseKey: '',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page29/E5/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page29/E5/2.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E5/3.jpg', input: 1, isCorrect: true },
        { url: 'img/FriendsPlus/Page29/E5/4.jpg', input: 1, isCorrect: true },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page29/E5/5.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },


}

export default json;