import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';



const json = {

  2: { // Exercise num
    unit: 'Culture',
    id: 'SB1-C-P51-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page51/E2/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page51/E2/2.jpg', audioUrl: "img/FriendsPlus/Page51/Audio/Hello. My name's Daiki. I'm from Japan..mp3" },
        { url: 'img/FriendsPlus/Page51/E2/3.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/australia.mp3' },
        { url: 'img/FriendsPlus/Page51/E2/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page51/E2/5.jpg', audioUrl: "img/FriendsPlus/Page51/Audio/Hi! I'm Jenny. I'm from Canada..mp3" },
        { url: 'img/FriendsPlus/Page51/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/VietNam.mp3' },
        { url: 'img/FriendsPlus/Page51/E2/7.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page51/E2/8.jpg', audioUrl: "img/FriendsPlus/Page51/Audio/Hi. My name's Charlie. I'm from the U.K..mp3" },
        { url: 'img/FriendsPlus/Page51/E2/9.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/Cambodia.mp3' },
        { url: 'img/FriendsPlus/Page51/E2/10.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page51/E2/11.jpg', audioUrl: "img/FriendsPlus/Page51/Audio/Hello. I'm Hoa. I'm from Viet Nam..mp3" },
        { url: 'img/FriendsPlus/Page51/E2/12.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/the U.k..mp3' },
        { url: 'img/FriendsPlus/Page51/E2/13.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page51/E2/14.jpg', audioUrl: "img/FriendsPlus/Page51/Audio/Hello. I'm Henry. I'm from Australia..mp3" },
        { url: 'img/FriendsPlus/Page51/E2/15.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/Japan.mp3' },
        { url: 'img/FriendsPlus/Page51/E2/16.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page51/E2/17.jpg', audioUrl: "img/FriendsPlus/Page51/Audio/Hi. My name's Sem. I'm from Combodia..mp3" },
        { url: 'img/FriendsPlus/Page51/E2/18.jpg', audioUrl: 'img/FriendsPlus/Page50/Audio/Canada.mp3' },
        { url: 'img/FriendsPlus/Page51/E2/19.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Culture',
    id: 'SB1-C-P51-E1',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page51/E3/1.jpg' },
      ],
    ]
  },


}
export default json;
