import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';

const json = {
    1: { // Exercise num
        unit: 'Culture 4',
        id: 'SB1-C-P55-E1',
        audio: 'img/FriendsPlus/Page55/Audio/audio.e1.mp3',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page55/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page55/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page55/Audio/tieude.e1.mp3' },
                { url: 'img/FriendsPlus/Page55/E1/3.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page55/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page55/Audio/fish.mp3' },
                { url: 'img/FriendsPlus/Page55/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page55/Audio/bird.mp3' },
                { url: 'img/FriendsPlus/Page55/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page55/Audio/flower.mp3' },
                { url: 'img/FriendsPlus/Page55/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page55/Audio/frog.mp3' },
            ],

        ],
    },
    2: { // Exercise num
        unit: 'Culture 4',
        id: 'SB1-C-P55-E2',
        audio: 'img/FriendsPlus/Page55/Audio/audio.e2.mp3',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page55/E2/1.jpg' },
                { url: 'img/FriendsPlus/Page55/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page55/Audio/tieude.e2.mp3' },
                { url: 'img/FriendsPlus/Page55/E2/3.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page55/E2/4.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page55/E2/5.jpg' },
                { url: 'img/FriendsPlus/Page55/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page55/Audio/fish.mp3' },
                { url: 'img/FriendsPlus/Page55/E2/7.jpg' },
            ],
            [
                // Column4
                { url: 'img/FriendsPlus/Page55/E2/8.jpg' },
                { url: 'img/FriendsPlus/Page55/E2/9.jpg', audioUrl: 'img/FriendsPlus/Page55/Audio/bird.mp3' },
                { url: 'img/FriendsPlus/Page55/E2/10.jpg' },
                { url: 'img/FriendsPlus/Page55/E2/11.jpg', audioUrl: 'img/FriendsPlus/Page55/Audio/flower.mp3' },
                { url: 'img/FriendsPlus/Page55/E2/12.jpg' },
            ],
            [
                // Column5
                { url: 'img/FriendsPlus/Page55/E2/13.jpg' },
                { url: 'img/FriendsPlus/Page55/E2/14.jpg', audioUrl: 'img/FriendsPlus/Page55/Audio/bird.mp3' },
                { url: 'img/FriendsPlus/Page55/E2/15.jpg', audioUrl: 'img/FriendsPlus/Page55/Audio/flower.mp3' },
                { url: 'img/FriendsPlus/Page55/E2/16.jpg', audioUrl: 'img/FriendsPlus/Page55/Audio/bird.mp3' },
                { url: 'img/FriendsPlus/Page55/E2/17.jpg', audioUrl: 'img/FriendsPlus/Page55/Audio/flower.mp3' },
                { url: 'img/FriendsPlus/Page55/E2/18.jpg' },
            ],

        ],
    },
    3: { // Exercise num
        unit: 'Culture 4',
        id: 'SB1-C-P55-E3',
        audio: '',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        question: [
        ],
        questionImage: [
            [
                { url: 'img/FriendsPlus/Page55/E3/1.jpg' },
            ],
        ]
    },



}

export default json;