

import T1 from '../../components/ExcerciseTypes/TypeIn/T1';
import T2 from '../../components/ExcerciseTypes/TypeIn/T2';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';

const json = {

  3: { // Exercise num
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page63/E3/Key/1.PNG',
    titleImage: 'img/FriendsPlus/Page30/E2/title.png',
    // titleQuestion: [{ num: '1', title: 'Look at the code and write the sentences.', color: 'black' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page63/E3/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page63/E3/2.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page63/E3/3.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/4.jpg' , input: true, answer: 'chicken/drink/chips' },
        { url: 'img/FriendsPlus/Page63/E3/5.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/6.jpg' , input: true, answer: 'cheese/eat/meal' },
        { url: 'img/FriendsPlus/Page63/E3/7.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page63/E3/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page63/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/10.jpg' , input: true, answer: 'chicken/drink/chips' },
        { url: 'img/FriendsPlus/Page63/E3/11.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/12.jpg' , input: true, answer: 'cheese/eat/meal' },
        { url: 'img/FriendsPlus/Page63/E3/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page63/E3/14.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page63/E3/15.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/16.jpg' , input: true, answer: 'chicken/drink/chips' },
        { url: 'img/FriendsPlus/Page63/E3/17.jpg' },
        { url: 'img/FriendsPlus/Page63/E3/18.jpg' , input: true, answer: 'cheese/eat/meal' },
        { url: 'img/FriendsPlus/Page63/E3/19.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page63/E3/20.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  4: { // Exercise num
    audio: '',
    video: '',
    component: T1,
    exerciseKey: 'img/FriendsPlus/Page63/E4/Key/1.PNG',
    titleQuestion: [{ color: '#085CAE', num: '4', title: 'Watch or listen. What food do the students like?' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p style='font-weight:600'>What food do the students like? # </p>",
        answer: ['The students like chicken salad, fish and chips, burgers (with cheese) and vegetable soup'],
        type: 'longAnwser'
      },
    ]
  },

  5: { // Exercise num
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page63/E5/Key/answerKey.png',
    // titleImage: "img/FriendsPlus/Page50/E15/1.png",
    titleQuestion: [{ num: '5', title: 'Watch or listen again and complete the Key Phrases with examples that the students talk about.', color: '#5196C7' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page63/E5/18.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page63/E5/1.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page63/E5/2.jpg' },
        { url: 'img/FriendsPlus/Page63/E5/3.jpg', input: true, answer: 'fish and chips' },
        { url: 'img/FriendsPlus/Page63/E5/4.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page63/E5/5.jpg' },
        { url: 'img/FriendsPlus/Page63/E5/6.jpg', input: true, answer: 'chicken salad' },
        { url: 'img/FriendsPlus/Page63/E5/7.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page63/E5/8.jpg' },
        { url: 'img/FriendsPlus/Page63/E5/9.jpg', input: true, answer: 'pasta' },
        { url: 'img/FriendsPlus/Page63/E5/10.jpg' },
      ],

      [
        // Column
        { url: 'img/FriendsPlus/Page63/E5/11.jpg' },
        { url: 'img/FriendsPlus/Page63/E5/12.jpg', input: true, answer: 'many school meals' },
        { url: 'img/FriendsPlus/Page63/E5/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page63/E5/14.jpg' },
        { url: 'img/FriendsPlus/Page63/E5/15.jpg', input: true, answer: 'chicken' },
        { url: 'img/FriendsPlus/Page63/E5/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page63/E5/17.jpg' },
      ],


    ],

    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },


  6: { // Exercise num
    audio: '',
    video: '',
    component: T2,
    titleImage: "img/FriendsPlus/Page63/E6/1.png",
    // titleQuestion: [{ color: '#E26D28', num: '5', title: 'Check the meanings of the verbs in the box. Look at the quiz and complete the questions with eight of the verbs. Listen and check your answers.' }],
    questionImage: [],
    questions: [
      {
        title:
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>" +
          "<p> # </p>",
        answer: [],
        type: 'longAnwser'
      },
    ]
  },
}
export default json;
