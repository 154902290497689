import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';

const json = {
    1: { // Exercise num
        unit: 'Unit 4',
        id: 'SB1-U4-P34-E1',
        audio: 'img/FriendsPlus/Page34/Audio/audio.e1.mp3',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page34/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page34/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/tieude.e1.mp3' },
                { url: 'img/FriendsPlus/Page34/E1/3.jpg' },
                { url: 'img/FriendsPlus/Page34/E1/4.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page34/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/L.mp3' },
                { url: 'img/FriendsPlus/Page34/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/lion.mp3' },
                { url: 'img/FriendsPlus/Page34/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/lollipop.mp3' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page34/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/M.mp3' },
                { url: 'img/FriendsPlus/Page34/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/man.mp3' },
                { url: 'img/FriendsPlus/Page34/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/mango.mp3' },
            ],

        ],
    },
    2: { // Exercise num
        unit: 'Unit 4',
        id: 'SB1-U4-P34-E2',
        audio: 'img/FriendsPlus/Page34/Audio/audio.e2.mp3',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page34/E2/1.jpg' },
                { url: 'img/FriendsPlus/Page34/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/tieude.e2.mp3' },
                { url: 'img/FriendsPlus/Page34/E2/3.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page34/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/L.mp3' },
                { url: 'img/FriendsPlus/Page34/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/lion.mp3' },
                { url: 'img/FriendsPlus/Page34/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/lollipop.mp3' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page34/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/M.mp3' },
                { url: 'img/FriendsPlus/Page34/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/man.mp3' },
                { url: 'img/FriendsPlus/Page34/E2/9.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/mango.mp3' },
            ],

        ],
    },
    3: { // Exercise num
        unit: 'Unit 4',
        id: 'SB1-U4-P34-E3',
        audio: '',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page34/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page34/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/L.mp3' },
                { url: 'img/FriendsPlus/Page34/E3/3.jpg' },
                { url: 'img/FriendsPlus/Page34/E3/4.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/A lion has a lollipop..mp3' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page34/E3/5.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/M.mp3' },
                { url: 'img/FriendsPlus/Page34/E3/6.jpg' },
                { url: 'img/FriendsPlus/Page34/E3/7.jpg', audioUrl: 'img/FriendsPlus/Page34/Audio/A man has a mango..mp3' },
            ],


        ],
    },


}

export default json;