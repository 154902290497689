
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine'



const json = {
  1: { // Exercise num
    unit: 'Unit 6',
    id: 'SB1-U6-P43-E1',
    audio: 'img/FriendsPlus/Page43/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page43/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page43/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page43/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page43/E1/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page43/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page43/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page43/Audio/anh1.e1.mp3' },
        { url: 'img/FriendsPlus/Page43/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page43/Audio/anh2.e1.mp3' },
        { url: 'img/FriendsPlus/Page43/E1/8.jpg' },
      ],


    ],
  },
  2: { // Exercise num
    unit: 'Unit 6',
    id: 'SB1-U6-P43-E2',
    audio: 'img/FriendsPlus/Page43/Audio/sing.e2.mp3',
    video: '',
    songLyric:
      "<p>My lunchbox, my lunchbox.</p>" +
      "<p>What’s in my lunchbox?</p>" +
      "<p>I have a sandwich.</p>" +
      "<p>I have a drink.</p>" +
      "<p>And I have an apple.</p>" +
      "<p>I have two cookies.</p>" +
      "<p>I have a pear.</p>" +
      "<p>And I have a banana.</p>" +
      "<p>My lunchbox, my lunchbox,</p>" +
      "<p>What’s in my lunchbox?</p>",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page43/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page43/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page43/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page43/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page43/E2/4.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 6',
    id: 'SB1-U6-P43-E3',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        // Column1
        { url: 'img/FriendsPlus/Page43/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page43/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page43/Audio/I have an apple..mp3' },
        { url: 'img/FriendsPlus/Page43/E3/3.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page43/E3/4.jpg' },
        { url: 'img/FriendsPlus/Page43/E3/5.jpg', audioUrl: 'img/FriendsPlus/Page43/Audio/I have pear..mp3' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page43/E3/6.jpg', audioUrl: 'img/FriendsPlus/Page43/Audio/I have two sandwiches..mp3' },
        { url: 'img/FriendsPlus/Page43/E3/7.jpg' },
      ],

    ]
  },
  4: { // Exercise num
    unit: 'Unit 6',
    id: 'SB1-U6-P43-E4',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page43/E4/1.jpg' },
      ],
    ]
  },

}

export default json;
