import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';


const json = {
  1: { // Exercise num
     unit: 'Starter',
    id: 'SB1-S-P8-E5',
    audio: 'img/FriendsPlus/Page8/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page8/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page8/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page8/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page8/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page8/E1/4.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page8/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page8/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page8/Audio/B.mp3' },
        { url: 'img/FriendsPlus/Page8/E1/7.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page8/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page8/Audio/boy.mp3' },
        { url: 'img/FriendsPlus/Page8/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page8/Audio/bat.mp3' },
        { url: 'img/FriendsPlus/Page8/E1/10.jpg' },
      ],

    ],
  },
  2: { // Exercise num
     unit: 'Starter',
    id: 'SB1-S-P8-E5',
    audio: 'img/FriendsPlus/Page8/Audio/audio.e2.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page8/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page8/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page8/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page8/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page8/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page8/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page8/Audio/B.mp3' },
        { url: 'img/FriendsPlus/Page8/E2/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page8/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page8/Audio/boy.mp3' },
        { url: 'img/FriendsPlus/Page8/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page8/Audio/bat.mp3' },
        { url: 'img/FriendsPlus/Page8/E2/9.jpg' },
      ],

    ],
  },
  3: { // Exercise num
     unit: 'Starter',
    id: 'SB1-S-P8-E5',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page8/E3/1.jpg' },
      ],
    ]
  },


}

export default json;