import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';


const json = {
  1: { // Exercise num
    unit: 'Starter',
    id: 'SB1-S-P6-E1',
    audio: 'img/FriendsPlus/Page6/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page6/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/3.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/4.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page6/E1/5.jpg' },
        { url: 'img/FriendsPlus/Page6/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/A.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/7.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page6/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/apple.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/annie.mp3' },
        { url: 'img/FriendsPlus/Page6/E1/10.jpg' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Starter',
    id: 'SB1-S-P6-E2',
    audio: 'img/FriendsPlus/Page6/Audio/audio.e2.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page6/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page6/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page6/E2/4.jpg' },
        { url: 'img/FriendsPlus/Page6/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/A.mp3' },
        { url: 'img/FriendsPlus/Page6/E2/6.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page6/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/apple.mp3' },
        { url: 'img/FriendsPlus/Page6/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page6/Audio/annie.mp3' },
        { url: 'img/FriendsPlus/Page6/E2/9.jpg' },
      ],

    ],
  },
  // 3: { // Exercise num
  //   unit: 'Starter',
  //   id: 'SB1-S-P6-E3',
  //   audio: '',
  //   video: '',
  //   component: D1,
  //   // recorder: true,
  //   isAllowSubmit: false,
  //   question: [
  //   ],
  //   questionImage: [
  //     [
  //       { url: 'img/FriendsPlus/Page6/E3/1.jpg' },
  //     ],
  //   ]
  // },


  3: { // Exercise num
    unit: 'Starter',
    id: 'SB1-S-P6-E3',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 6,
    exerciseKey: 'img/FriendsPlus/Page6/E3/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page6/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page6/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page6/E3/4.jpg', input: 2, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/5.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page6/E3/6.jpg', input: 4, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/7.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page6/E3/8.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page6/E3/9.jpg' },
        { url: 'img/FriendsPlus/Page6/E3/10.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/11.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page6/E3/12.jpg', input: 8, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/13.jpg', input: 9, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/14.jpg', input: 10, isCorrect: true },
        { url: 'img/FriendsPlus/Page6/E3/15.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },

}

export default json;