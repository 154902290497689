import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';



const json = {
  1: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P40-E1',
    audio: 'img/FriendsPlus/Page40/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page40/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page40/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page40/E1/4.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/E.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/5.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/F.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/G.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/7.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/H.mp3' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page40/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/egg.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/fig.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/10.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/goat.mp3' },
        { url: 'img/FriendsPlus/Page40/E1/11.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/hat.mp3' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P40-E2',
    audio: 'img/FriendsPlus/Page40/Audio/audio.e2.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page40/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page40/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page40/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page40/E2/4.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P40-E3',
    audio: 'img/FriendsPlus/Page40/Audio/audio.e3.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page40/E3/1.jpg' },
        { url: 'img/FriendsPlus/Page40/E3/2.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/tieude.e3.mp3' },
        { url: 'img/FriendsPlus/Page40/E3/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page40/E3/4.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/F.e3.mp3' },
        { url: 'img/FriendsPlus/Page40/E3/5.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/G.e3.mp3' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page40/E3/6.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/leaf.mp3' },
        { url: 'img/FriendsPlus/Page40/E3/7.jpg', audioUrl: 'img/FriendsPlus/Page40/Audio/dog.mp3' },
      ],

    ],
  },
  4: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P40-E4',
    audio: '',
    video: '',
    component: DesignUnderLine,
    totalInput: 2,
    exerciseKey: 'img/FriendsPlus/Page40/E4/Key/answerKey.png',
    titleImage: '',
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page40/E4/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page40/E4/2.jpg' },
        { url: 'img/FriendsPlus/Page40/E4/3.jpg', input: 1 },
        { url: 'img/FriendsPlus/Page40/E4/4.jpg', input: 2 },
        { url: 'img/FriendsPlus/Page40/E4/5.jpg', input: 3 },
        { url: 'img/FriendsPlus/Page40/E4/6.jpg' },
        { url: 'img/FriendsPlus/Page40/E4/7.jpg', input: 4 },
        { url: 'img/FriendsPlus/Page40/E4/8.jpg', input: 5 },
        { url: 'img/FriendsPlus/Page40/E4/9.jpg', input: 6, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E4/10.jpg' },
        { url: 'img/FriendsPlus/Page40/E4/11.jpg', input: 7 },
        { url: 'img/FriendsPlus/Page40/E4/12.jpg', input: 8 },
        { url: 'img/FriendsPlus/Page40/E4/13.jpg', input: 9 },
        { url: 'img/FriendsPlus/Page40/E4/14.jpg' },
        { url: 'img/FriendsPlus/Page40/E4/15.jpg', input: 10 },
        { url: 'img/FriendsPlus/Page40/E4/16.jpg' },
        { url: 'img/FriendsPlus/Page40/E4/17.jpg', input: 11 },
        { url: 'img/FriendsPlus/Page40/E4/18.jpg', input: 12 },
        { url: 'img/FriendsPlus/Page40/E4/19.jpg', input: 13 },
        { url: 'img/FriendsPlus/Page40/E4/20.jpg', input: 14, isCorrect: true },
        { url: 'img/FriendsPlus/Page40/E4/21.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page40/E4/22.jpg' },
      ],


    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  5: { // Exercise num
    unit: 'Unit 5',
    id: 'SB1-U5-P40-E5',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page40/E5/1.jpg' },
      ],
    ]
  },
}

export default json;
