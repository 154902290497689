import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';

const json = {
  1: { // Exercise num
    unit: 'Unit 4',
    id: 'SB1-U4-P30-E1',
    audio: 'img/FriendsPlus/Page30/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page30/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page30/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page30/E1/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page30/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/bird.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/bear.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/hippo.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/crocodile.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/tiger.mp3' },
      ],

    ],
  },
  2: { // Exercise num
    unit: 'Unit 4',
    id: 'SB1-U4-P30-E2',
    audio: 'img/FriendsPlus/Page30/Audio/audio.e2.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page30/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page30/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/3.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page30/E2/4.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/bird.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/bear.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/6.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/hippo.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/crocodile.mp3' },
        { url: 'img/FriendsPlus/Page30/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/tiger.mp3' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 4',
    id: 'SB1-U4-P30-E3',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        // Column1
        { url: 'img/FriendsPlus/Page30/E3/1.jpg' },
      ],
      [
        // Column2
        { url: 'img/FriendsPlus/Page30/E3/2.jpg' },
        { url: 'img/FriendsPlus/Page30/E3/3.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/tiger.mp3' },
        { url: 'img/FriendsPlus/Page30/E3/4.jpg' },
      ],
      [
        // Column3
        { url: 'img/FriendsPlus/Page30/E3/5.jpg' },
      ],
      [
        // Column4
        { url: 'img/FriendsPlus/Page30/E3/6.jpg' },
        { url: 'img/FriendsPlus/Page30/E3/7.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/hippo.mp3' },
        { url: 'img/FriendsPlus/Page30/E3/8.jpg' },
      ],
      [
        // Column5
        { url: 'img/FriendsPlus/Page30/E3/9.jpg' },
      ],
      [
        // Column6
        { url: 'img/FriendsPlus/Page30/E3/10.jpg' },
        { url: 'img/FriendsPlus/Page30/E3/11.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/bird.mp3' },
        { url: 'img/FriendsPlus/Page30/E3/12.jpg' },
      ],
      [
        // Column7
        { url: 'img/FriendsPlus/Page30/E3/13.jpg' },
        { url: 'img/FriendsPlus/Page30/E3/14.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/bear.mp3' },
        { url: 'img/FriendsPlus/Page30/E3/15.jpg' },
      ],
      [
        // Column8
        { url: 'img/FriendsPlus/Page30/E3/16.jpg' },
        { url: 'img/FriendsPlus/Page30/E3/17.jpg', audioUrl: 'img/FriendsPlus/Page30/Audio/crocodile.mp3' },
        { url: 'img/FriendsPlus/Page30/E3/18.jpg' },
      ],
      [
        // Column9
        { url: 'img/FriendsPlus/Page30/E3/19.jpg' },
      ],

    ],
  },

}

export default json;