import DesignUnderLine from '../../components/ExcerciseTypes/Design/DesignUnderLine';
import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';


const json = {
    1: { // Exercise num
        unit: 'Unit 1',
        id: 'SB1-U1-P14-E1',
        audio: 'img/FriendsPlus/Page14/Audio/audio.e1.mp3',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page14/E1/1.jpg' },
                { url: 'img/FriendsPlus/Page14/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page14/Audio/tieude.e1.mp3' },
                { url: 'img/FriendsPlus/Page14/E1/3.jpg' },
                { url: 'img/FriendsPlus/Page14/E1/4.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page14/E1/5.jpg' },
                { url: 'img/FriendsPlus/Page14/E1/6.jpg', audioUrl: 'img/FriendsPlus/Page14/Audio/D.mp3' },
                { url: 'img/FriendsPlus/Page14/E1/7.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page14/E1/8.jpg', audioUrl: 'img/FriendsPlus/Page14/Audio/dog.mp3' },
                { url: 'img/FriendsPlus/Page14/E1/9.jpg', audioUrl: 'img/FriendsPlus/Page14/Audio/duck.mp3' },
                { url: 'img/FriendsPlus/Page14/E1/10.jpg' },
            ],

        ],
    },
    2: { // Exercise num
        unit: 'Unit 1',
        id: 'SB1-U1-P14-E2',
        audio: 'img/FriendsPlus/Page14/Audio/audio.e2.mp3',
        video: '',
        component: D1,
        recorder: true,
        isAllowSubmit: false,
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page14/E2/1.jpg' },
                { url: 'img/FriendsPlus/Page14/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page14/Audio/tieude.e2.mp3' },
                { url: 'img/FriendsPlus/Page14/E2/3.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page14/E2/4.jpg' },
                { url: 'img/FriendsPlus/Page14/E2/5.jpg', audioUrl: 'img/FriendsPlus/Page14/Audio/D.mp3' },
                { url: 'img/FriendsPlus/Page14/E2/6.jpg' },
            ],
            [
                // Column3
                { url: 'img/FriendsPlus/Page14/E2/7.jpg', audioUrl: 'img/FriendsPlus/Page14/Audio/dog.mp3' },
                { url: 'img/FriendsPlus/Page14/E2/8.jpg', audioUrl: 'img/FriendsPlus/Page14/Audio/duck.mp3' },
                { url: 'img/FriendsPlus/Page14/E2/9.jpg' },
            ],

        ],
    },
    3: { // Exercise num
        unit: 'Unit 1',
        id: 'SB1-U1-P14-E3',
        audio: '',
        video: '',
        component: DesignUnderLine,
        totalInput: 4,
        exerciseKey: 'img/FriendsPlus/Page14/E3/Key/answerKey.png',
        titleImage: '',
        questionImage: [ // Row
            [
                // Column1
                { url: 'img/FriendsPlus/Page14/E3/1.jpg' },
            ],
            [
                // Column2
                { url: 'img/FriendsPlus/Page14/E3/2.jpg' },
                { url: 'img/FriendsPlus/Page14/E3/3.jpg', input: 1 },
                { url: 'img/FriendsPlus/Page14/E3/4.jpg', input: 1, isCorrect: true },
                { url: 'img/FriendsPlus/Page14/E3/5.jpg' },
                { url: 'img/FriendsPlus/Page14/E3/6.jpg', input: 2, isCorrect: true },
                { url: 'img/FriendsPlus/Page14/E3/7.jpg', input: 2 },
                { url: 'img/FriendsPlus/Page14/E3/8.jpg' },
                { url: 'img/FriendsPlus/Page14/E3/9.jpg', input: 3 },
                { url: 'img/FriendsPlus/Page14/E3/10.jpg', input: 3, isCorrect: true },
                { url: 'img/FriendsPlus/Page14/E3/11.jpg' },
                { url: 'img/FriendsPlus/Page14/E3/12.jpg', input: 4, isCorrect: true },
                { url: 'img/FriendsPlus/Page14/E3/13.jpg', input: 4 },
                { url: 'img/FriendsPlus/Page14/E3/14.jpg' },
            ],


        ],
        questions: [
            {
                title: 'The dictionaries are # the shelf',
                answer: ['table']
            },
        ]
    },


}

export default json;