import D1 from '../../components/ExcerciseTypes/Design/TypeInNoSubmit';
import UnderLine from '../../components/ExcerciseTypes/Design/UnderLine';


const json = {
  1: { // Exercise num
    unit: 'Unit 2',
    id: 'SB1-U2-P17-E1',
    audio: 'img/FriendsPlus/Page17/Audio/audio.e1.mp3',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page17/E1/1.jpg' },
        { url: 'img/FriendsPlus/Page17/E1/2.jpg', audioUrl: 'img/FriendsPlus/Page17/Audio/tieude.e1.mp3' },
        { url: 'img/FriendsPlus/Page17/E1/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E1/4.jpg' },
      ],
    ],
  },
  2: { // Exercise num
    unit: 'Unit 2',
    id: 'SB1-U2-P17-E2',
    audio: 'img/FriendsPlus/Page17/Audio/sing.e2.mp3',
    video: '',
    songLyric:
      "<p>Chair, desk, notebook, crayon, pencil ...<p>" +
      "<p>What’s this?<p>" +
      "<p>What’s this? It’s a pencil.<p>" +
      "<p>What’s this? It’s a desk.<p>" +
      "<p>What’s this? It’s a crayon.<p>" +
      "<p>What’s this? It’s a chair.<p>" +
      "<p>Chair, desk, notebook, crayon, pencil ...<p>" +
      "<p>What’s this?<p>" +
      "<p>What’s this? It’s a pencil.<p>" +
      "<p>What’s this? It’s a desk.<p>" +
      "<p>What’s this? It’s a crayon.<p>" +
      "<p>What’s this? It’s a chair.<p>" +
      "<p>Chair, desk, notebook, crayon, pencil ...<p>" +
      "<p>What’s this?<p>",
    component: UnderLine,
    // exerciseKey: '',
    recorder: true,
    isAllowSubmit: false,
    questionImage: [ // Row
      [
        { url: 'img/FriendsPlus/Page17/E2/1.jpg' },
        { url: 'img/FriendsPlus/Page17/E2/2.jpg', audioUrl: 'img/FriendsPlus/Page17/Audio/tieude.e2.mp3' },
        { url: 'img/FriendsPlus/Page17/E2/3.jpg' },
      ],
      [
        { url: 'img/FriendsPlus/Page17/E2/4.jpg' },
      ],

    ],
  },
  3: { // Exercise num
    unit: 'Unit 2',
    id: 'SB1-U2-P17-E3',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page17/E3/1.jpg' },
      ],
    ]
  },
  4: { // Exercise num
    unit: 'Unit 2',
    id: 'SB1-U2-P17-E4',
    audio: '',
    video: '',
    component: D1,
    recorder: true,
    isAllowSubmit: false,
    question: [
    ],
    questionImage: [
      [
        { url: 'img/FriendsPlus/Page17/E4/1.jpg' },
      ],
    ]
  },


}

export default json;