
import MC1 from '../../components/ExcerciseTypes/MultipleChoice/MC1';
import T6 from '../../components/ExcerciseTypes/TypeIn/T6';
import D1 from '../../components/ExcerciseTypes/Design/TypeIn';


const json = {
  1: { // Exercise num
    audio: '',
    video: '',
    component: D1,
    exerciseKey: 'img/FriendsPlus/Page58/E1/Key/1.png',
    titleImage: '',
    titleQuestion: [{ num: '1', title: 'Complete the words in the sentences.', color:'#553f9f' }],
    questionImage: [ // Row
      [
        // Column
        { url: 'img/FriendsPlus/Page58/E1/1.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page58/E1/2.jpg' },
        { url: 'img/FriendsPlus/Page58/E1/3.jpg', input: true, answer: 'abbit' },
        { url: 'img/FriendsPlus/Page58/E1/4.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page58/E1/5.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page58/E1/6.jpg' },
        { url: 'img/FriendsPlus/Page58/E1/7.jpg', input: true, answer: 'rog' },
        { url: 'img/FriendsPlus/Page58/E1/8.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page58/E1/9.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page58/E1/10.jpg' },
        { url: 'img/FriendsPlus/Page58/E1/11.jpg', input: true, answer: 'ctopus' },
        { url: 'img/FriendsPlus/Page58/E1/12.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page58/E1/13.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page58/E1/14.jpg' },
        { url: 'img/FriendsPlus/Page58/E1/15.jpg', input: true, answer: 'amel' },
        { url: 'img/FriendsPlus/Page58/E1/16.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page58/E1/17.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page58/E1/18.jpg' },
        { url: 'img/FriendsPlus/Page58/E1/19.jpg', input: true, answer: 'utterfly' },
        { url: 'img/FriendsPlus/Page58/E1/20.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page58/E1/21.jpg' },

      ], [
        // Column
        { url: 'img/FriendsPlus/Page58/E1/22.jpg' },
        { url: 'img/FriendsPlus/Page58/E1/23.jpg', input: true, answer: 'ish' },
        { url: 'img/FriendsPlus/Page58/E1/24.jpg' },
      ],
      [
        // Column
        { url: 'img/FriendsPlus/Page58/E1/25.jpg' },
      ],
    ],
    questions: [
      {
        title: 'The dictionaries are # the shelf',
        answer: ['table']
      },
    ]
  },
  2: { // Exercise num
    audio: '',
    video: '',
    component: MC1,
    exerciseKey: 'img/FriendsPlus/Page58/E2/Key/answerKey.png',
    titleQuestion: [{ num: '2', title: 'Choose the correct word sand complete the text.', color: '#3B3074' }],
    titleImage: 'img/FriendsPlus/Page58/E2/titleImage.jpg',
    questionImage: [ // Row
      [
        // Column
        { url: 'http://' },
        { url: 'http://', input: true },
        { url: 'http://' },
      ],
    ],
    questions: [
      {
        // title: '',
        answers: [
          { image: "", isCorrect: true, right: "", text: "rarest" },
          { image: "", isCorrect: false, right: "", text: "most common" },
          { image: "", isCorrect: false, right: "", text: "most colourful" },
        ],
        no: 1,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "common" },
          { image: "", isCorrect: false, right: "", text: "dull" },
          { image: "", isCorrect: true, right: "", text: "beautiful" },
        ],
        no: 2,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "colourful" },
          { image: "", isCorrect: true, right: "", text: "ugly" },
          { image: "", isCorrect: false, right: "", text: "rare" },
        ],
        no: 3,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "beautiful" },
          { image: "", isCorrect: false, right: "", text: "rare" },
          { image: "", isCorrect: true, right: "", text: "dull" },
        ],
        no: 4,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: false, right: "", text: "dull" },
          { image: "", isCorrect: true, right: "", text: "colourful" },
          { image: "", isCorrect: false, right: "", text: "ugly" },
        ],
        no: 5,
        question: '',
        type: '',
      },
      {
        // title: '',
        answers: [
          { image: "", isCorrect: true, right: "", text: "more common" },
          { image: "", isCorrect: false, right: "", text: "rarer" },
          { image: "", isCorrect: false, right: "", text: "uglier" },
        ],
        no: 6,
        question: '',
        type: '',
      },

    ],
  },
  3: { // Exercise num
    audio: '',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page58/E3/Key/1.png',
    // titleImage: "img/FriendsPlus/Page56/E1/1.png",
    titleQuestion: [{ color: '#3F3478', num: '3', title: "Complete the sentences with a superlative adjective." }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p><span style='font-weight:600'>1.</span> This village isn’t noisy. It’s one of # </p>" +
          "<p>(quiet) places in our country.</p>" +
          "<p><span style='font-weight:600'>2.</span> I like that Italian restaurant – they have # </p>" +
          "<p>(big) pizzas in town!</p>" +
          "<p><span style='font-weight:600'>3.</span> The museum in our capital city is fantastic.</p>" +
          "<p>It’s one of # (interesting)</p>" +
          "<p><span style='font-weight:600'>4.</span> They never go to that swimming pool. It’s # </p>" +
          "<p>(bad) swimming pool in the area.</p>" +
          "<p><span style='font-weight:600'>5.</span> This office building is horrible. It’s</p>" +
          "<p># (ugly) building in the city.</p>" +
          "<p><span style='font-weight:600'>6.</span> ‘Do you like this park?’ ‘Yes, it’s one of </p>" +
          "<p># (beautiful) parks in the country.’</p>" +
          "<p><span style='font-weight:600'>7.</span> There are a lot of rats in the world – they are one </p>" +
          "<p>of # (common) animals.</p>" +
          "<p><span style='font-weight:600'>8.</span> We like that American actor. He is</p>" +
          "<p># (good) actor in Hollywood.</p></div>",
        answer: [
          "the quietest",
          "the biggest",
          "the most interesting",
          "the worst",
          "the ugliest",
          "the most beautiful",
          "the most common",
          "the best"
        ],
        type: 'longAnwser'
      },
    ]
  },
  4: { // Exercise num
    audio: 'img/FriendsPlus/Page58/E4/Audio/G6 SB Track 1.48.WAV',
    video: '',
    component: T6,
    exerciseKey: 'img/FriendsPlus/Page58/E4/Key/1.png',
    // titleImage: "img/FriendsPlus/Page56/E1/1.png",
    titleQuestion: [{ color: '#3F3478', num: '4', title: "Listen to the radio programme about polar bears. Write True or False." }],
    questionImage: [],
    questions: [
      {
        title:
          "<div style='font-family:Rubik-Medium'><p>Polar bears can ... .</p>"
          + "<p><span style='font-weight:600'>1.</span> grow to 290 kilos # </p>" +
          "<p><span style='font-weight:600'>2.</span> see colours # </p>" +
          "<p><span style='font-weight:600'>3.</span> jump two or more metres out of the water # </p>" +
          "<p><span style='font-weight:600'>4.</span> run up to fourteen kilometres an hour # </p>" +
          "<p><span style='font-weight:600'>5.</span> swim for days # </p>" +
          "<p><span style='font-weight:600'>6.</span> survive a year without food # </p></div>",
        answer: [
          'false',
          'true',
          'true',
          'false',
          'true',
          'false',
        ],
        // type: 'longAnwser'
      },
    ]
  },
}
export default json